



























































































// Vue
import Vue from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
// Services
import AuthService from "@/core/services/auth.service";
import { _Error } from "@/core/_models/_error.model";

export default Vue.extend({
  mixins: [validationMixin],
  name: "ForgotPassword",

  data() {
    return {
      isLoading: false,
      successResetLinkSent: false,
      errorResetLinkSent: false,
      valid: false,
      email: ""
    };
  },

  validations: {
    email: { required, email }
  },

  methods: {
    /**
     * Sends a password reset link to the email provided.
     *
     * @author Nick Brahimir
     */
    sendResetLink: function (): void {
      this.isLoading = true;
      this.errorResetLinkSent = false;
      this.successResetLinkSent = false;

      AuthService.resetPassword(this.email)
        .then((data: any | _Error) => {
          // Check for null data response.
          if (!data) {
            this.errorResetLinkSent = true;
            return;
          }

          // Password email successfully sent.
          this.successResetLinkSent = true;
        })
        .then(() => {
          this.isLoading = false;
        });

      this.clearForm;
    },

    /**
     * Clears the form.
     *
     * @author Nick Brahimir
     */
    clearForm: function (): void {
      this.$v.$reset();
      this.email = "";
    }
  },

  computed: {
    /**
     * Parses any email errors.
     *
     * @author Nick Brahimir
     */
    emailErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Please enter a valid email");
      return errors;
    }
  }
});
